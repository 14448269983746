
html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202423;
}

.htmlScreen iframe {
    width: 1024px;
    height: 670px;
    border: none;
    border-radius: 20px;
    background-color: black;
}

.loading-screen {
    color: white;
    font-size: 3rem;

}

@keyframes blink {
    50% { color: transparent;
    }
}

.loading-dot {
    animation: 1s blink infinite;
}

.loading-dot:nth-child(2) {
    animation-delay: 250ms;
}

.loading-dot:nth-child(3) {
    animation-delay: 500ms;
}